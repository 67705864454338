// Generated by Framer (c3ab1ed)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NXE8Koxoj"];

const variantClassNames = {NXE8Koxoj: "framer-v-3fksrq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "NXE8Koxoj", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NXE8Koxoj", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DzjQj", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-3fksrq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"NXE8Koxoj"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-1ez7u5l"} data-framer-name={"Ellipse 11"} layoutDependency={layoutDependency} layoutId={"I340:2834;332:2173"} style={{backgroundColor: "rgba(212, 44, 82, 0.12)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transition={transition}/><motion.div className={"framer-8mdskl"} data-framer-name={"Ellipse 12"} layoutDependency={layoutDependency} layoutId={"I340:2834;332:2174"} style={{backgroundColor: "rgb(228, 0, 70)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DzjQj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DzjQj * { box-sizing: border-box; }", ".framer-DzjQj .framer-3fksrq { height: 48px; overflow: visible; position: relative; width: 48px; }", ".framer-DzjQj .framer-1ez7u5l { aspect-ratio: 1 / 1; bottom: var(--framer-aspect-ratio-supported, 0px); flex: none; height: 48px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: transform; }", ".framer-DzjQj .framer-8mdskl { aspect-ratio: 1 / 1; bottom: var(--framer-aspect-ratio-supported, 20px); flex: none; height: 8px; left: 20px; overflow: hidden; position: absolute; right: 20px; top: 20px; will-change: transform; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerApu2umEit: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerApu2umEit;

FramerApu2umEit.displayName = "Cursor";

FramerApu2umEit.defaultProps = {height: 48, width: 48};

addFonts(FramerApu2umEit, [])